import React from 'react'
import { getDataDependencies } from 'services/api'
import fetchSingleType from 'src/lib/Strapi/fetchSingleType'
import PreviewBanner from 'components/shared/PreviewBanner'

import HomeHero from 'components/shared/HomeHero'
import SliceManager from 'components/shared/SliceManager'
import { revalidating } from 'src/lib/staticProps'

const Home = ({ pageData: { homeHero, slices }, preview }) => {
  return (
    <>
      {preview && <PreviewBanner />}
      {homeHero && <HomeHero {...homeHero} />}
      {slices && <SliceManager slices={slices} />}
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  const data = await fetchSingleType('homepage', preview)

  const extendedData = await getDataDependencies(data)

  return revalidating({ props: { pageData: extendedData, preview } })
}

export default Home
